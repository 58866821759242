@font-face {
    font-family: "GraphIcons";
    src: url("./GraphIcons.eot?a33c2c0a3c1a5a4b40b14f4e3b2356dd#iefix") format("embedded-opentype"),
url("./GraphIcons.woff2?a33c2c0a3c1a5a4b40b14f4e3b2356dd") format("woff2"),
url("./GraphIcons.woff?a33c2c0a3c1a5a4b40b14f4e3b2356dd") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: GraphIcons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-custom:before {
    content: "\f101";
}
.icon-event:before {
    content: "\f102";
}
.icon-group:before {
    content: "\f103";
}
.icon-organization:before {
    content: "\f105";
}
.icon-project:before {
    content: "\f106";
}
.icon-role:before {
    content: "\f107";
}
.icon-skill:before {
    content: "\f108";
}
